import { onRefused } from './onRefused';

import { storage } from '../storage';

import { ACTIONS, ACTION_USED } from '../../constants/paymentMethodsHub';

const response = {
  providerMessage: '',
};

const commonEmitMock = jest.fn();

const composablePayload = {
  contextData: {
    isRedirected: false,
    flowTracking: {
      currentAction: ACTIONS.add,
      addIsEmbedded: false,
    },
  },
  commonEmit: (param, value) => {
    commonEmitMock(param, value);
  },
  originalResponse: response,
  parentActionCallback: jest.fn(),
};

describe('When the "onRefused" method is called', () => {
  beforeEach(() => {
    jest.resetModules();
  });

  describe('And action is "Add"', () => {
    it('Then "onAddPaymentMethodError" is called', () => {
      onRefused(composablePayload);

      expect(composablePayload.parentActionCallback).toHaveBeenCalledWith('onAddPaymentMethodError', '');
    });
  });

  describe('And action is "List"', () => {
    it('Then the "on:payment-method-refused" event is emitted', () => {
      composablePayload.contextData.flowTracking.currentAction = ACTIONS.list;

      onRefused(composablePayload);

      expect(commonEmitMock).toHaveBeenCalledWith('on:add-payment-method-refused', { canRetry: undefined, refusedMessage: '' });
    });
  });

  describe('And is being redirected', () => {
    it('Then the event is emitted with a value', () => {
      composablePayload.contextData.isRedirected = true;
      composablePayload.contextData.flowTracking.currentAction = null;

      storage.add(ACTION_USED, ACTIONS.add);

      composablePayload.originalResponse.providerMessage = 'refusedTestMessage';

      onRefused(composablePayload);

      expect(commonEmitMock).toHaveBeenCalledWith('on:add-payment-method-refused', { refusedMessage: 'refusedTestMessage' });
    });
  });
});
